import React from 'react';
import { Box, Container, } from '@mui/material';
import useTitle from '../../components/useTitle';

function InstructorBox(props) {
    const { children } = props;
    return (<Box p={3} my={3} sx={{ borderRadius: '10px', textAlign: 'center', boxShadow: 'rgba(149, 157, 165, 0.3) 0px 8px 24px' }}>{children}</Box>);
}

function InstructorName(props) {
    const { children } = props;
    return (<Box sx={{ fontSize: '20pt' }}><strong>{children}</strong></Box>);
}

function InstructorDesc(props) {
    const { children } = props;
    return (<Box sx={{ fontSize: '18pt' }}>{children}</Box>);
}

function FacultyPage() {
    useTitle('Faculty');
    return (
        <Container sx={{ fontSize: '16pt' }}>
            <Box>
                <h1>Faculty</h1>
            </Box>
            <InstructorBox>
                <Box pb={2}>
                    <img
                        src="/img/ps-hank-ps-natasja.jpg"
                        alt="Pastors Hank & Natasja Yonce"
                        style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 12px', borderRadius: '10px' }}
                    />
                </Box>
                <InstructorName>Pastors Hank &amp; Natasja Yonce</InstructorName>
                <InstructorDesc>President and Vice President</InstructorDesc>
            </InstructorBox>
            <InstructorBox>
                <InstructorName>Pastor Victoria Rosa</InstructorName>
                <InstructorDesc>Dean</InstructorDesc>
            </InstructorBox>
            <InstructorBox>
                <InstructorName>Pastor Victor Rosendo</InstructorName>
                <InstructorDesc>Instructor</InstructorDesc>
            </InstructorBox>
            <InstructorBox>
                <InstructorName>Professor Luis Rodriguez</InstructorName>
                <InstructorDesc>Instructor</InstructorDesc>
            </InstructorBox>
            <InstructorBox>
                <InstructorName>Professor James Dawson</InstructorName>
                <InstructorDesc>Instructor</InstructorDesc>
            </InstructorBox>
            <InstructorBox>
                <InstructorName>Professor Lisa Bosnak</InstructorName>
                <InstructorDesc>Instructor</InstructorDesc>
            </InstructorBox>
            <InstructorBox>
                <InstructorName>Evangelist Josh Radford</InstructorName>
                <InstructorDesc>Instructor</InstructorDesc>
            </InstructorBox>
            <InstructorBox>
                <InstructorName>Nikki Ray</InstructorName>
                <InstructorDesc>Teaching Assistant (TA)</InstructorDesc>
            </InstructorBox>
        </Container>
    );
}

export default FacultyPage;
