import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import useTitle from '../../components/useTitle';

function ContactUsPage() {
    useTitle('Contact');
    return (
        <>
            <Container>
                <Grid container sx={{ width: '100%' }}>
                    <Grid item sm={12} md={4} p={2}>
                        <Box>
                            <h1>Contact</h1>
                        </Box>
                        <Box pt={3}>
                            <strong>Academics</strong><br />
                            <strong>Student Contact</strong><br />
                            <strong>General Inquiries &amp; Admissions</strong><br />
                            Tel: +1 (386) 837-1815<br />
                            <a href="mailto:fubi@myfaithunited.com">fubi@myfaithunited.com</a>
                        </Box>
                    </Grid>
                    <Grid item sm={12} md={8} py={3}>
                        <Box sx={{ fontSize: '1rem', }}>
                            <iframe title="Faith United Map" style={{ height: '400px', width: '100%' }} frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=600&amp;hl=en&amp;q=Faith United Church, 813 Flight Line Blvd, DeLand, FL 32724&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                        </Box>
                    </Grid>
                </Grid>
            </Container >
        </>
    );
}

export default ContactUsPage;
