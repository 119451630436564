import React from 'react';
import { Box, Container } from '@mui/material';
import useTitle from '../../components/useTitle';

function FaqsPage() {
    useTitle('Frequently Asked Questions');
    return (
        <Container>
            <Box>
                <h1>Frequently Asked Questions</h1>
            </Box>
            <Box pb={10} sx={{ fontSize: '1.3rem' }}>
                <h3>ACCEPTANCE PROCESS</h3>
                <p>
                    Q: How do I apply for Faith United Bible Institute?<br />
                    A: Apply online by <a href="/admissions/apply-now">clicking here</a>, or call the Admissions Office.
                </p>
                <p>
                    Q: How do I know if I have been accepted into the Faith United Bible Institute?<br />
                    A: You will receive a letter of acceptance via email.
                </p>
                <p>
                    Q: When are the general fee and tuition due?<br />
                    A: The general fee is due upon acceptance to FUBI. Tuition payments can be made before or during the week of Orientation.
                </p>
                <p>
                    Q: Are all students required to attend Orientation?<br />
                    A: All students, whether incoming or continuing students are required to attend orientation in its entirety.
                </p>
                <h3>STUDENT LIFE</h3>
                <p>
                    Q:  Are there any on-campus dorms for students?<br />
                    A:  At this time there are no dorms on campus. However, upon acceptance, the student will receive a listing of local apartments and private renting opportunities.
                </p>
                <p>
                    Q:  As a FUBI student, are there requirements outside of classes?<br />
                    A: Each week, students are required to attend the Sunday morning service and one evening service at Faith United Church (see Student Life page). Students are also required to serve in a ministry of helps. Students are also required to win ten souls per quarter, attend outreaches and all revival meetings, and participate in one soul-winning Saturday per quarter.
                </p>
                <p>
                    Q: Is there a local church that students can attend?<br />
                    A: Faith United Institute is in association with the Faith United Church. Attendance is required to Faith United Church unless the student is previously attending another church in the local area.
                </p>
                <h3>ACCREDITATION</h3>
                <p>
                    Q: What kind of accreditation does Faith United Bible Institute have?<br />
                    A: Faith United Bible Institute is an accredited member with Transworld Accrediting Commission International (TACI). <a href="/academics/accreditation">Click here to learn more.</a>
                </p>
            </Box>
        </Container>
    );
}

export default FaqsPage;
