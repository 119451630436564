import React from 'react';
import { Box, Container, Grid, Paper, Tabs, Tab } from '@mui/material';
import useTitle from '../../components/useTitle';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function ClassName(props) {
    const { children } = props;
    return (
        <>
            <strong>{children}</strong><br />
        </>
    );
}

function ClassDescription(props) {
    const { children } = props;
    return (
        <>
            {children}<br /><br />
        </>
    );
}

function CourseInformationPage() {
    useTitle('Course Information');

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container>
            <Box>
                <h1>Course Information</h1>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Paper>
                    <Tabs value={value} onChange={handleChange} aria-label="tabs">
                        <Tab sx={{ fontFamily: 'inherit' }} label="Program of Study" id="tab-0" aria-controls="tabpanel-0" />
                        <Tab sx={{ fontFamily: 'inherit' }} label="Schedule" id="tab-1" aria-controls="tabpanel-1" />
                        <Tab sx={{ fontFamily: 'inherit' }} label="Course Description" id="tab-2" aria-controls="tabpanel-2" />
                    </Tabs>
                </Paper>
            </Box>
            <TabPanel value={value} index={0}>
                <h3>PROGRAM OF STUDY</h3>
                <p>The Faith United Bible Institute was established to equip men and women to be victorious in every area of life. It is our desire to produce men and women of God who are knowledgeable in the Word of God, strong in faith principles, compassionate to the needs of others, and as bold as a lion!</p>
                <p>Faith United Bible Institute offers Diploma in Ministry, Associate in Ministry, and Bachelors in Ministry programs.</p>
                <p>
                    Upon successful completion of all requirements for the two-year program, the student will obtain a Diploma in Ministry.
                    The program itself may serve as a catalyst for a greater more intimate walk with God and provide the student with tools for more productive Biblical study.
                    This program may also serve as the initial step toward further structured theological study.
                </p>
                <p>Upon successful completion of all requirements for the four-year program, the student will obtain an Associates in Ministry.</p>
                <p>Upon successful completion of an additional one-year fulltime internship, the student will obtain a Bachelors in Ministry.</p>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <h3>CLASS SCHEDULE (ALL YEARS)</h3>
                <p>Monday &amp; Thursday Nights</p>
                <p>1st Hour -- 7:00-7:50pm</p>
                <p>2nd Hour -- 8:00-8:50pm</p>
                <p>3rd Hour -- 9:00-9:50pm</p>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <h3>COURSE DESCRIPTION</h3>
                <Grid container>
                    <Grid item xs={12} md={6} pr={2}>
                        <h3>1st and 2nd Year Class Descriptions</h3>
                        <ClassName>Blood Covenant</ClassName>
                        <ClassDescription>This course will involve biblical study into the covenants, their creation and duration, with particular emphasis on the Blood Covenant and its implications for the believer. This course will also examine both of the old and new covenant, their similarities and their application for today.</ClassDescription>
                        <ClassName>Eschatology</ClassName>
                        <ClassDescription>Eschatology is the branch of theology that deals with the final consummation of all things. We will covering such subject matter as the rapture, the tribulation, judgment, and the life to come.</ClassDescription>
                        <ClassName>12 Pillars of Faith</ClassName>
                        <ClassDescription>There are foundational truths in God's Word that, as Holy Ghost-filled believers, we must truly comprehend and grasp to be foundational truths, thereby making them pillars of our faith. This course will expound on these twelve pillars: 1) My Heart, 2) My Mission, 3) My Worship, 4) My Family, 5) My Treasure, 6) My Father, 7) My Comforter, 8) My Road Map, 9) My Absolutes, 10) My Prayer Life, 11) My Crowns, 12) My Jesus.</ClassDescription>
                        <ClassName>The Anointing</ClassName>
                        <ClassDescription>In this series on the Anointing, Dr. Rodney Howard-Browne reveals some key truths to the anointing learned in over 30 years of ministry. The student will learn that the anointing is present to heal, deliver, set free, and set ablaze anyone who is hungry and desperate enough to be used of God.</ClassDescription>
                        <ClassName>Authority of the Believer</ClassName>
                        <ClassDescription>As believers we have authority that some of us may not be aware of. We may even be walking in it and not knowing it. This course is to help us open Gods word on the subject of “Authority” so that as believers we may not only live out our God given authority, but also begin to see God's perfect plan unfold in our lives on a daily basis.</ClassDescription>
                        <ClassName>Bible Doctrines</ClassName>
                        <ClassDescription>In this course we will take time to study the subjects of what we believe and understand why we believe them. We will also begin to build a solid foundation on the biblical truths that our doctrine is built upon, as well as exposing false doctrines concerning what we believe.</ClassDescription>
                        <ClassName>Christ the Healer</ClassName>
                        <ClassDescription>This course will help to establish, from the scriptures, that it is God's will to heal all today and to correct wrong thinking about certain passages of scripture misunderstood by those who oppose divine healing.</ClassDescription>
                        <ClassName>Christian Lifestyle</ClassName>
                        <ClassDescription>What are the outward and inward evidences of a Christian lifestyle? When we truly practice a Christian lifestyle, the inward evidence becomes obvious. God's glory and power pours out upon all those around us. Those who live the Christian lifestyle live a pure and confident life on the inside and outside. In the epistles we find what is expected of us inwardly and outwardly.</ClassDescription>
                        <ClassName>Church History</ClassName>
                        <ClassDescription>The purpose of this course is to help the student become familiar with the periods of Church history, important leaders, significant issues, great movements, and the characteristics of the True Church. Emphasis will be given to the Pentecostal/Charismatic movements that have arisen since the book of Acts, and have continued throughout history to the present day.</ClassDescription>
                        <ClassName>Faith I</ClassName>
                        <ClassDescription>An understanding of the different types of Faith that are required to enable the student to minister in a way that will not only impact their lives but most importantly, the lives of others.</ClassDescription>
                        <ClassName>Gifts of the Spirit</ClassName>
                        <ClassDescription>This is a study course of the Gifts of the Spirit according to 1 Corinthians 12. The student will learn who the gifts are from, who the gifts are for, and what their purpose is, according to the Bible.</ClassDescription>
                        <ClassName>Helps &amp; The Local Church I</ClassName>
                        <ClassDescription>This course will give the student a biblical understanding of the helps ministry in relation to the local church which works in conjunction with a world wide ministry.</ClassDescription>
                        <ClassName>The Holy Spirit</ClassName>
                        <ClassDescription>A course on the Person and the work of the Holy Spirit. Emphasis is made on foundational truths that will radically change the students way of thinking. Students will discover what it's like to know the person of the Holy Spirit in a great way – to really know Him as best friend.</ClassDescription>
                        <ClassName>Matters of the Heart</ClassName>
                        <ClassDescription>A study on the heart of the believer and its importance in the Christian walk. The course will show how to develop one's heart and what it means to keep one's heart with all diligence.</ClassDescription>
                        <ClassName>Missions I</ClassName>
                        <ClassDescription>God has one unified, global purpose for all He does. This course introduces the exciting biblical, historical, cultural, and strategic dimensions of His plan. It addresses key issues: the basis of and necessity for world missions, and the status of and plan for world missions. Students are introduced to basic information needed to pursue missionary training or to help lead their local church in its global ministry.</ClassDescription>
                        <ClassName>New Creation Realities</ClassName>
                        <ClassDescription>An investigation into the Hidden man of the Heart, the spirit man, according to the great plan of redemption. This course will scripturally unveil who we are in Christ through the new birth.</ClassDescription>
                        <ClassName>New Testament Survey</ClassName>
                        <ClassDescription>The student will obtain a general knowledge of the theme and contents of each New Testament book and gain an understanding of the meaning and significance of the historical events and messages of the New Testament.</ClassDescription>
                        <ClassName>Old Testament Survey</ClassName>
                        <ClassDescription>The student will obtain a general knowledge of the theme and contents of each Old Testament book and gain an understanding of the meaning and significance of the historical events and messages of the Old Testament.</ClassDescription>
                        <ClassName>Prayer I</ClassName>
                        <ClassDescription>This course will study what prayer is and how to get prayers answered; the purpose, the necessity, the possibilities, the power, the weapons, and the essentials of prayer.</ClassDescription>
                        <ClassName>Seeing Jesus as He Really Is</ClassName>
                        <ClassDescription>This course offers an intimate and inspirational look at the life of Jesus. Using the text Seeing Jesus As He Really Is, this course will explore the Gospels as they reveal Jesus.</ClassDescription>
                        <ClassName>Soul Winning I</ClassName>
                        <ClassDescription>This is a course on introducing Christ to others in the work place, on foreign fields, and local neighborhoods. Each student will experience the joy of leading others to Christ and then follow up with them to make them disciples.</ClassDescription>
                        <ClassName>Stewardship I</ClassName>
                        <ClassDescription>This course will familiarize the student with many scriptures that pertain to finances and stewardship. A foundation is given for personal life as well as equipping with truths needed for preaching on stewardship and giving in ministry.</ClassDescription>
                        <ClassName>Submission &amp; Authority</ClassName>
                        <ClassDescription>The student will realize the importance of submission and authority in their daily walk of faith and learn how to be prepared to submit to authority while learning how to walk in God-given authority.</ClassDescription>
                        <ClassName>Spiritual Growth</ClassName>
                        <ClassDescription>The student will learn how to locate themselves spiritually and grow into the mature Christian that God desires them to be.</ClassDescription>
                        <ClassName>Worship as a Lifestyle</ClassName>
                        <ClassDescription>This course will give the student a thorough understanding of the foundation, purpose and practice of worship in the believer's life. Emphasis will be placed on worship as a lifestyle for the believer, discovering how man's entire existence is tied to living in and depending upon God's presence.</ClassDescription>
                    </Grid>
                    <Grid item xs={12} md={6} pr={2}>
                        <h3>3rd and 4th Year Class Descriptions</h3>
                        <ClassName>Book of Acts</ClassName>
                        <ClassDescription>This course will give you an understanding of The Book of Acts with the emphasis on The Work of the Holy Spirit throughout.</ClassDescription>
                        <ClassName>Christian Lifestyle II</ClassName>
                        <ClassDescription>This course will help the student develop strong spiritual and biblical discernment of what a true Christian lifestyle looks like. The emphasis will be on purity and holiness in life, ministry and conduct as a believer. We will study how the Gifts of the Spirit and especially the fruit of the Spirit cancel the works of the flesh.</ClassDescription>
                        <ClassName>Devils & Demons: HTDWT</ClassName>
                        <ClassDescription>This course will ground you, the student, in the Word of God concerning the activity of angels and demons. It will also build faith for dealing scripturally with both Godly angels and fallen angels/demons.</ClassDescription>
                        <ClassName>Faith II</ClassName>
                        <ClassDescription>An understanding of the different types of Faith that are required to enable the student to minister in a way that will not only impact their lives but most importantly, the lives of others.</ClassDescription>
                        <ClassName>Homiletics I</ClassName>
                        <ClassDescription>A class designed to help each student to gain confidence in his/her own ability and in the ability of the Holy Spirit to help him/her in pulpit ministry and to be aware of ways of improving his/her presentation.</ClassDescription>
                        <ClassName>Homiletics II</ClassName>
                        <ClassDescription>A class designed to help each student to gain confidence in his/her own ability and in the ability of the Holy Spirit to help him/her in pulpit ministry and to be aware of ways of improving his/her presentation.</ClassDescription>
                        <ClassName>Leadership</ClassName>
                        <ClassDescription>This course will develop a biblical foundation for leadership. Students will discover principles that will help them to develop leadership qualities in their lives.</ClassDescription>
                        <ClassName>The Love Walk</ClassName>
                        <ClassDescription>In this course the student will be encouraged, exhorted and provoked to press into God, make a decision and a commitment to really get to the know the Father. The student will learn that by walking in the love of God, one can walk in victory in every area of life.</ClassDescription>
                        <ClassName>The Minister's Family</ClassName>
                        <ClassDescription>This course's purpose is to give the student called into ministry a thorough understanding of the practicalities and demands of ministry placed on the family and how to deal with them successfully; in other words, how to balance family and ministry correctly.</ClassDescription>
                        <ClassName>Ministry Gifts</ClassName>
                        <ClassDescription>This course will cover Ministry Gifts and the Divine Call. Students will develop an understanding that, as ministers of the Gospel, we are entrusted with God’s strength and might.</ClassDescription>
                        <ClassName>Ministerial Excellence</ClassName>
                        <ClassDescription>This course will develop the students ability to walk in excellence in all areas of life and ministry.</ClassDescription>
                        <ClassName>Missions II</ClassName>
                        <ClassDescription>A continuation of exciting biblical, historical, cultural, and strategic dimensions of God’s plan to evangelize the world with the Gospel. Students are introduced to more information needed to pursue a calling into missions or to help lead a local church in its global ministry.</ClassDescription>
                        <ClassName>Old Testament Men of Faith</ClassName>
                        <ClassDescription>In this class you will gain insight into the lives of many of the Old Testament saints and become aware that they utilized faith and its principles in a living and dynamic way.</ClassDescription>
                        <ClassName>Prayer II</ClassName>
                        <ClassDescription>This course will study what prayer is and how to get prayers answered; the purpose, the necessity, the possibilities, the power, the weapons, and the essentials of prayer.</ClassDescription>
                        <ClassName>Preparation for Ministry</ClassName>
                        <ClassDescription>Through both lecture and hands-on experience, this course was designed to help the student with the practical and business aspects of ministry and to equip them with the tools needed to begin a ministry.</ClassDescription>
                        <ClassName>Revival: Nothing More, Nothing Less, Nothing Else</ClassName>
                        <ClassDescription>In this class, Dr. Rodney Howard-Browne explains that real revival is not just a touch but a change – it is coming back to your first love, Jesus. Every student needs a touch from the Master’s hand, a touch that will impact and mark their entire life. When the student is touched, revived, and changed by the Holy Spirit, not only will they know it but the world will hear it in their words and see it in their actions and attitude. You are guaranteed that when you are done with this course you will never be the same.</ClassDescription>
                        <ClassName>Shaking the Nations</ClassName>
                        <ClassDescription>The spirit of God is always moving across the nations of the earth. God is waiting for the church to rise up and be bold for this hour. He is looking for people that He can show Himself strong on their behalf. It’s time to shake the nations by the power of the gospel through signs and wonders. God is waiting on you.</ClassDescription>
                        <ClassName>Signs &amp; Wonders</ClassName>
                        <ClassDescription>The student will learn that signs and wonders should be an everyday occurrence in the believer’s life. The student will also come to an understanding that signs, wonders and miracles are not just for the church, but they are for the demonstration of the power of God outside the four walls of the church.</ClassDescription>
                        <ClassName>Soul Winning II</ClassName>
                        <ClassDescription>This is a course on introducing Christ to others in the work place, on foreign fields, and local neighborhoods. Each student will experience the joy of leading others to Christ and then follow up with them to make them disciples.</ClassDescription>
                        <ClassName>Stewardship II</ClassName>
                        <ClassDescription>This course will familiarize the student with many scriptures that pertain to finances and stewardship. A foundation is given for personal life as well as equipping with truths needed for preaching on stewardship and giving in ministry.</ClassDescription>
                        <ClassName>The Tabernacle of Moses</ClassName>
                        <ClassDescription>This is a Biblical study of the frame work and spiritual meaning of the Tabernacle of Moses in regards to its example, typology, and practical implications for the New Covenant.</ClassDescription>
                        <ClassName>The Healing and Miracle Ministry of Jesus</ClassName>
                        <ClassDescription>This is a Biblical study of the supernatural healing and miracle ministry of Jesus.</ClassDescription>
                        <ClassName>The Name of Jesus</ClassName>
                        <ClassDescription>This is a Biblical study of the power of The Name of Jesus in a believer's life.</ClassDescription>
                        <ClassName>Vision</ClassName>
                        <ClassDescription>Learn what "vision" is and be instructed how to recognize God's vision for your life and the importance of having patience to wait for the vision to have its perfect work in your life.</ClassDescription>
                    </Grid>
                </Grid>
                <p>* Courses are subject to change.</p>
            </TabPanel>
        </Container>
    );
}

export default CourseInformationPage;
