import React from 'react';
import { Box, Container } from '@mui/material';
import useTitle from '../../components/useTitle';

function AboutFubiPage() {
    useTitle('About');
    return (
        <>
            <Container>
                <Box>
                    <h1>About Faith United Bible Institute</h1>
                </Box>
                <Box pb={10}>
                    <Box sx={{ float: 'left', position: 'relative', minWidth: '280px' }}>
                        <img sx={{ display: 'inline' }} src="/img/fubi-logo-new.png" alt="FUBI Logo" width="260" height="260" />
                    </Box>
                    <p>The Faith United Bible Institute program exists for the purpose of training men and women in the spirit of revival for ministry in the 21st century. This is not just for the full-time minister, but for all of God’s people who are hungry for more. It is a place where the Word of God and the Holy Spirit come together to produce life, birth ministries, and launch individuals to fulfill the call of God.</p>
                    <p>The Faith United Bible Institute program provides quality Spirit-filled biblical instruction, academic training, and practical education to men and women called into the Five-Fold Ministry. The ministry program also provides instruction for those who desire to become better equipped in other areas of Christian service.</p>
                    <p>The Faith United Bible Institute's campus is located in Deland, Florida. Its facilities are shared by Faith United Church. Classrooms are housed within two separate buildings on the campus.</p>
                </Box>
            </Container>
        </>
    );
}

export default AboutFubiPage;
