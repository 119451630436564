import React from 'react';
import { Box, Container } from '@mui/material';
import useTitle from '../../components/useTitle';

function StatementOfFaithPage() {
    useTitle('Statement of Faith');
    return (
        <>
            <Container>
                <Box>
                    <h1>Statement of Faith</h1>
                </Box>
                <Box pb={10} sx={{ fontSize: '1.2rem', }}>
                    <h3>THE SCRIPTURES</h3>
                    <p>The Bible is the inspired Word of God. It is the product of holy men of old who spoke and wrote as they were moved by The Holy Spirit. We accept the New Covenant as recorded in the New Testament as our infallible guide in matters pertaining to conduct and doctrine (II Tim. 3:16, I Thess. 2:13, II Peter 1:21).</p>
                    <h3>THE GODHEAD</h3>
                    <p>Our God is one, but manifested in three persons; the Father, the Son and the Holy Spirit, being co-equal (Phil 2:6). God, The Father, is greater than all: the sender of the Word (LOGOS) and the Begetter (John 14:28, John 16:28, John 1:14). The Son is the Word flesh-covered, the One Begotten, and has existed with the Father from the beginning (John 1:1, John 1:18, John 1:14). The Holy Spirit proceeds forth from both the Father and the Son and is eternal. (John 15:26).</p>
                    <h3>MAN: HIS FALL AND REDEMPTION</h3>
                    <p>Man is a created being made in the likeness and image of God, but through Adam’s transgression and fall, sin came into the world. “All have sinned and fallen short of the glory of God.” As it is written, “There is none righteous, no not one.” Jesus Christ, the Son of God, was manifested to undo the work of the devil and gave his life and shed His blood to redeem and restore man back to God (Rom. 5:14, Rom 3:10, Rom. 3:23, I John 3:8). Salvation is the gift of God to man, separate from works and the Law, and is made operative by grace through faith in Jesus Christ, producing works acceptable to God (Eph. 2:8).</p>
                    <h3>ETERNAL LIFE AND THE NEW BIRTH</h3>
                    <p>The New Birth is man's first step toward salvation is godly sorrow that worketh repentance. The New Birth is necessary to all men, and when experienced, produces eternal life (II Cor. 7:10, I John 5:12, John 3:3-5).</p>
                    <h3>WATER BAPTISM</h3>
                    <p>Baptism in water by immersion, is a direct commandment of our Lord, and is for believers only. The ordinance is a symbol of the Christian’s identification with Christ in His death, burial, and resurrection (Matt. 28:19, Rom. 6:4, Col. 2:12, Acts 8:36-39). The following recommendations regarding the water baptismal formula is adopted; to wit. “On the confession of your faith in the Lord Jesus Christ, the Son of God, and by the authority, I baptize you in the Name of the Father, and the Son and the Holy Ghost. Amen.”</p>
                    <h3>BAPTISM IN THE HOLY GHOST</h3>
                    <p>The Baptism in The Holy Ghost and fire is a gift from God as promised by the Lord Jesus Christ to all believers in this dispensation and is received subsequent to the New Birth. This experience is accompanied by the initial evidence of speaking in other tongues as the Holy Spirit Himself gives utterance. (Matt. 3:11, John 14:16-17, Acts 1:9, Acts 2:38-39, Acts 19:1-7, Acts 2:4).</p>
                    <h3>SANCTIFICATION</h3>
                    <p>The Bible teaches that without holiness no man can see the Lord. We believe in the Doctrine of Sanctification as a definite, yet progressive work of grace, commencing at the time of regeneration and continuing until the consummation of salvation at Christ’s return (Heb. 12:14, I Thess. 5:23, II Peter 3:18, Phil. 3:12-14, I Cor. 1:30).</p>
                    <h3>DIVINE HEALING</h3>
                    <p>Healing is for the physical ills of the human body and is wrought by the power of God through the prayer of faith, and by the laying on of hands. It is provided for in the atonement of Christ, and is the privilege of every member of the Church today. (Mark 16:18, James 5:14-15, I Peter 2:24, Matt: 8:17, Isa. 53:4-5).</p>
                    <h3>RESURRECTION OF THE JUST AND THE RETURN OF OUR LORD</h3>
                    <p>The angels said to Jesus’ disciples. “This same Jesus shall so come, in like manner as ye have seen Him go into heaven.” His coming is imminent. When He comes, “…The dead in Christ shall rise first, then we which are alive and remain shall be caught together with them in the clouds to meet the Lord in the air…” (Acts 1:11, I Thess. 4:16-17). Following the Tribulation, and His one thousand year reign (Rev. 20:6), He shall return to earth as King of kings and Lord of lords together with his saints, who shall be kings and priests.</p>
                    <h3>HELL AND ETERNAL RETRIBUTION</h3>
                    <p>The one who physically dies in his sins without accepting Christ is hopelessly and eternally lost in the Lake of Fire and therefore has no further opportunity of hearing the Gospel or repenting. The Lake of Fire is literal. The term “eternal” used in describing the duration of the punishment of the damned in the Lake of Fire carry the same thought and meaning of endless existence as used in denoting the duration of joy and ecstasy of the saints in the presence of God. (Heb. 9:27, Rev. 19:20).</p>
                </Box>
            </Container>
        </>
    );
}

export default StatementOfFaithPage;
