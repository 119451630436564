import { useRef, useEffect } from 'react'

export default function useTitle(title, prevailOnUnmount = false) {
    const defaultTitle = useRef(document.title);

    useEffect(() => {
        document.title = title + ' - Faith United Bible Institute';
    }, [title]);

    useEffect(() => () => {
        if (!prevailOnUnmount) {
            document.title = defaultTitle.current;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}