import React from 'react';
import { Box, Container } from '@mui/material';
import useTitle from '../../components/useTitle';

function FinancialInformationPage() {
    useTitle('Financial Information');
    return (
        <Container>
            <Box>
                <h1>Financial Information</h1>
            </Box>
            <Box>
                <h3>LOW TUITION COMMITMENT</h3>
                <p>Faith United Bible Institute has the standpoint that a quality spiritual education that trains an individual for service to the Lord Jesus Christ, should not leave that individual with oppressive financial debt.</p>
                <p>We, therefore, have not sought the very costly regional accreditation that leads to qualification for government-guaranteed student loans. Institutions using such loans generally charge the maximum tuition allowable, leaving the student with a sizeable debt at the completion of their education. For those called to the ministry, it can be very difficult to obey God's calling with this type of debt lingering over their finances. Therefore, Faith United Bible Institute has elected a less costly, yet just as valid independent accreditation through Transworld Accrediting Commission International.</p>
                <p>Faith United Bible Institute also does not participate in government-sponsored grants, for our approach is to provide the most affordable, quality, spiritual education that we can as a ministry to the Lord and His servants.</p>
            </Box>
            <Box my={2}>
                <h3>COSTS - TUITION AND FEES</h3>
                1ST YEAR/2ND YEAR BIBLE INSTITUTE PROGRAM<br />
                $50.00  Application Fee (Non-refundable)<br />
                $250.00  General Fee (Non-refundable)<br />
                $5,500.00  Tuition Costs<br />
                <strong>$5,800.00  USD (Per Year)</strong>
                <p>Please note:  The Application and General Fees are non-refundable.  The General fee covers graduation cap & gown, technology fees, facility usage, and other student-related materials and activities.</p>
                <p>Textbooks are not included in the cost of tuition. They are purchased separately and are the responsibility of the student.</p>
                <p>Payment is due at the end of orientation and registration. Payments are to be made at the FUBI Office. FUBI will accept cash, checks, and credit cards for payment. The registration fee is non-refundable outside of the first week of classes.</p>
                <h3>REFUND POLICY</h3>
                <p>If for any reason you find it necessary to withdraw from school during the course of the quarter, the tuition will be refunded according to the schedule established in the student handbook.</p>
                <p>If you are expelled from school, no refund will be given. All requests for refunds must be made within 7 business days of the last attended class in order to be considered for a refund. Please allow thirty days for any refund.</p>
                <h3>FINANCIAL ASSISTANCE</h3>
                <p>At this time, Faith United Bible Institute does not offer any student loans, grants, or other financial assistance. Students are responsible for all costs. Payment installments are available to assist the student with tuition payments.</p>
                <p>Scholarships are available. Any scholarships offered are at the discretion of the President of Faith United Bible Institute. Students who receive a scholarship to FUBI must follow scholarship guidelines and will be evaluated each quarter on an individual basis.</p>
                <p>*All fees are subject to change.</p>
            </Box>
        </Container>
    );
}

export default FinancialInformationPage;
