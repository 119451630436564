import React from 'react';
import { Box, Container } from '@mui/material';
import useTitle from '../../components/useTitle';

function StudentLifePage() {
    useTitle('Student Life');
    return (
        <Container>
            <Box>
                <h1>Student Life</h1>
            </Box>
            <Box pb={10}>
                <p>In addition to powerful, Word-based and anointed classes, the FUBI student has many opportunities on a weekly basis to learn and develop through invaluable hands-on ministry training, as well as grow in the things of God alongside other on-fire classmates. Below is just a glimpse of what students can experience.</p>
                <h3>Evangelism</h3>
                <p>Students attending Faith United Bible Institute not only learn about ministry in the classroom, but also practically. In addition to regular class times, students attend at least one soulwinning class per academic school week. These classes usually include evanglism training and actual ministry time in local communitites.</p>
                <h3>Local Church</h3>
                <p>Students attending FUBI are required to attend Faith United Church (unless a student already was a member of a church in the local area prior to school attendance). Attendance and involvement at Faith United Church enhances the student's training in the ministry as they receive from the presence of God and serve in the local church.</p>
                <h3>Ministry of Helps</h3>
                <p>Students of Faith United Bible Institute are required to serve in the helps ministry at Faith United Church (or their own local church as listed above) once a week. There are so many opportunities for the student to learn how ministry works in the local church through different types of departments, including Ushering, Food Ministry, Productions, Worship, Greeters, and more.</p>
                <p>Students are required to attend all services (evening services may only be missed if a student has to work).</p>
            </Box>
        </Container>
    );
}

export default StudentLifePage;
