import React from 'react';
import { Box, Container } from '@mui/material';
import useTitle from '../../components/useTitle';

function ApplicationProcedurePage() {
    useTitle('Application Procedure');
    return (
        <>
            <Container>
                <Box>
                    <h1>Application Procedure</h1>
                </Box>
                <Box pb={10}>
                    <h4>Faith United Bible Institute reserves the right of admission.</h4>
                    <h3>ADMISSIONS GUIDELINES</h3>
                    <ul>
                        <li><a href="/admissions/apply-now">Go to "Apply Now"</a>. If you are a new student select the US Application. For returning students, select the US Returning Student Application.</li>
                        <li>Please answer all questions. If a question does not apply to you, write N/A (not applicable) in the space provided.</li>
                        <li>All applications must be submitted with non-refundable general and registration fees payable online.</li>
                        <li>You must upload a recent portrait-style color photograph of yourself in the online application.</li>
                        <li>Upload a copy of your High School Diploma, GED, or your latest earned degree.</li>
                        <li>Complete, sign (electronic signature), and submit the application.</li>
                        <li>Submit three (3) references: One (1) pastoral reference and two (2) personal references - personal references should be people who know you well and can speak on your behalf regarding your character, gifts, and calling. Please do not use relatives for any of the required references.</li>
                        <li>Once the admissions office has reviewed your application, you will be notified in writing of your acceptance or denial within 2-4 weeks.</li>
                    </ul>
                    <h3>APPLICATION FOR ADMISSIONS</h3>
                    To apply for entrance into Faith United Bible Institute, the following actions are required:
                    <ul>
                        <li>Complete the online Application for Admissions that applies to your program and submit it with a non-refundable $50.00 application fee. The application fee for returning students is $50.00.</li>
                        <li>Students who are utilizing a payment plan for tuition must complete a Financial Agreement Form. (Payment Plans are only authorized by Faith United Bible Institute Administrator.)</li>
                        <li>Students wishing to receive academic credit for their previous schooling must have an official transcript sent to the Office of Admissions. The student is responsible for all fees relating to the acquisition of transcripts. Transcript request forms are available upon request.</li>
                        <li>After submitting their completed application, new students may be required to have a pre-admission interview with a school staff member.</li>
                        <li>Students who have been accepted, but are prevented from attending the current school year, may postpone their enrollment for up to 12 months. After 12 months students must reapply.</li>
                        <li>Applications are valid for 12 months.</li>
                    </ul>
                    Once all necessary information has been submitted, the pre-admission interview has been conducted (if applicable), and the student has been accepted, the student may then attend orientation and register for classes.
                    <h3>RE-ENROLLMENT & INTERRUPTED EDUCATION</h3>
                    <p>Students who leave in good standing may reapply for active status at a later date. If a student violates or varies from the standards set by the school or Faith United Church as a whole, Faith United Bible Institute reserves the right to revoke any such student's degree. Any student having an outstanding balance will be prohibited from readmission to this institution until all accounts are settled. Any student who has been expelled from school for disciplinary reasons will be denied readmission until approval has been granted by the President of the college.</p>
                    <p>Students in good standing who desire to return to school after a 12 months absence or after interrupted education will be classified as returning students. These students need to complete a new student application online and pay the required course tuition, textbook, and other fees.</p>
                </Box>
            </Container>
        </>
    );
}

export default ApplicationProcedurePage;