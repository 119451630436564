import React from 'react';
import { Box, Container } from '@mui/material';
import useTitle from '../../components/useTitle';

function VisionAndPurposePage() {
    useTitle('Vision & Purpose');
    return (
        <>
            <Container>
                <Box>
                    <h1>Vision &amp; Purpose</h1>
                </Box>
                <Box pb={10}>
                    Our mission is to raise a generation that will lift Jesus high in the nations and carry Him to the world.
                    This is a place where the Word of God and the Holy Spirit come together to produce life, birth ministries, and launch individuals to fulfill the call of God.
                </Box>
            </Container>
        </>
    );
}

export default VisionAndPurposePage;
