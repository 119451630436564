import React from 'react';
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

const ColorAccent = '#003683';

const FubiLogo = () => {
    return (
        <Box sx={{ display: 'block' }}>
            <Button sx={{ fontFamily: 'inherit', textAlign: 'left', lineHeight: '2rem', }} href="/">
                <Box sx={{
                    display: 'flex',
                    lineHeight: { xs: '1.1rem', sm: '1.3rem', md: '1.5rem' },
                    alignItems: 'center',
                    color: ColorAccent,
                }}>
                    <Box sx={{
                        width: { xs: '50px', sm: '65px', md: '80px' },
                        height: { xs: '50px', sm: '65px', md: '80px' },
                    }}>
                        <img src="/img/fubi-logo-new.png" alt="Logo" width="100%" height="100%" />
                    </Box>
                    <Box sx={{
                        paddingLeft: '1rem',
                        width: { xs: '200px', sm: '250px', md: '300px' },
                    }}>
                        <Box sx={{
                            fontSize: { xs: '1.3rem', sm: '1.8rem', md: '2rem' },
                        }}>FAITH UNITED</Box>
                        <Box sx={{
                            fontSize: { xs: '1.0rem', sm: '1.4rem', md: '1.6rem' },
                        }}>BIBLE INSTITUTE</Box>
                    </Box>
                </Box>
            </Button>
        </Box>
    );
};

export default FubiLogo;