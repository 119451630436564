import React from 'react';
import { Box, Container } from '@mui/material';
import useTitle from '../../components/useTitle';

function TranscriptRequestPage() {
    useTitle('Transcript Request');
    return (
        <Container>
            <Box>
                <h1>Transcript Request</h1>
            </Box>
            <Box pb={10} sx={{ fontSize: '1.5rem' }}>
                <p>Requests for Transcript should be emailed to our primary email address: <a href="mailto:fubi@myfaithunited.com">fubi@myfaithunited.com</a>. Please include the following information to assist us when processing your request:</p>
                <ul>
                    <li>Student Full Name</li>
                    <li>Student Date of Birth</li>
                    <li>Most recent year student attended</li>
                </ul>
                <p>We will process your request through email and respond as promptly as possible.</p>
            </Box>
        </Container>
    );
}

export default TranscriptRequestPage;
