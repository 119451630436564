import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// SITE ROOT PAGE INCLUDES
import Frame from './pages/Frame';
import HomePage from './pages/Home';
import ErrorPage from './pages/Error';

// ABOUT SECTION PAGE INCLUDES
import GivingPage from './pages/sections/about/Giving';
import AboutFubiPage from './pages/sections/about/AboutFubi';
import VisionAndPurposePage from './pages/sections/about/VisionAndPurpose';
import StatementOfFaithPage from './pages/sections/about/StatementOfFaith';
import ContactUsPage from './pages/sections/about/ContactUs';

// ADMISSIONS SECTION PAGE INCLUDES
import ApplyNowPage from './pages/sections/admissions/ApplyNow';
import FacultyPage from './pages/sections/admissions/Faculty';
import FinancialInformationPage from './pages/sections/admissions/FinancialInformation';
import ApplicationProcedurePage from './pages/sections/admissions/ApplicationProcedure';
import FaqsPage from './pages/sections/admissions/Faqs';

// ACADEMICS SECTION PAGE INCLUDES
import CourseInformationPage from './pages/sections/academics/CourseInformation';
import TranscriptRequestPage from './pages/sections/academics/TranscriptRequest';
import AccreditationPage from './pages/sections/academics/Accreditation';

// STUDENTS SECTION PAGE INCLUDES
import FacilitiesPage from './pages/sections/students/Facilities';
import StudentLifePage from './pages/sections/students/StudentLife';
import DressCodePage from './pages/sections/students/DressCode';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Frame />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      }, {
        path: "about/giving/",
        element: <GivingPage />,
      }, {
        path: "about/about-fubi/",
        element: <AboutFubiPage />,
      }, {
        path: "about/vision-and-purpose/",
        element: <VisionAndPurposePage />,
      }, {
        path: "about/statement-of-faith/",
        element: <StatementOfFaithPage />,
      }, {
        path: "about/contact-us/",
        element: <ContactUsPage />,
      }, {
        path: "admissions/apply-now/",
        element: <ApplyNowPage />,
      }, {
        path: "admissions/faculty/",
        element: <FacultyPage />,
      }, {
        path: "admissions/financial-information/",
        element: <FinancialInformationPage />,
      }, {
        path: "admissions/application-procedure/",
        element: <ApplicationProcedurePage />,
      }, {
        path: "admissions/faqs/",
        element: <FaqsPage />,
      }, {
        path: "academics/course-information/",
        element: <CourseInformationPage />,
      }, {
        path: "academics/transcript-request/",
        element: <TranscriptRequestPage />,
      }, {
        path: "academics/accreditation/",
        element: <AccreditationPage />,
      }, {
        path: "students/facilities/",
        element: <FacilitiesPage />,
      }, {
        path: "students/student-life",
        element: <StudentLifePage />,
      }, {
        path: "students/dress-code",
        element: <DressCodePage />,
      }
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
