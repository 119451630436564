import React from 'react';
import { Box, Container } from '@mui/material';
import useTitle from '../../components/useTitle';

function FacilitiesPage() {
    useTitle('Facilities');
    return (
        <Container>
            <Box>
                <h1>Facilities</h1>
            </Box>
            <Box pb={10}>
                <h3>LOCATION</h3>
                <p>Faith United Bible Institute is located next to the Deland Municipal Airport in Deland, Florida. <br />813 Flightline Blvd<br />Deland, Florida 32724</p>
                <h3>HOUSING</h3>
                <p>Faith United Bible Institute does not have dormitories or housing on campus at this time. However, you can request a local housing list following acceptance to FUBI.</p>
                <h3>PARKING</h3>
                <p>Faith United Bible Institute provides ample parking for faculty, staff, and students.</p>
                <h3>SAFETY AND SECURITY</h3>
                <p>Faith United Bible Institute has met all local, state, and federal building, health and safety codes. Initial building codes have been completed and sanitation and fire inspections are conducted yearly. An on-site custodial staff works to ensure cleanliness.</p>
            </Box>
        </Container>
    );
}

export default FacilitiesPage;
