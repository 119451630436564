import React from 'react';
import { Box, Button, Container, } from '@mui/material';
import styled from '@emotion/styled'
import useTitle from './components/useTitle';

const ColorBackground = "#f7f7f7";
const ColorAccent = '#003683';
const HRGold = styled.div`
  background-color: #ffbd17;
  height: 0.5px;
`;

function HomePage() {
    useTitle('Home');
    return (
        <>
            <Box sx={{
                backgroundImage: "url('/img/rotator-03-ds.JPG')",
                backgroundPosition: 'center',
                backgroundRepeat: "no-repeat",
                backgroundSize: 'cover',
                width: '100%',
                height: { xs: '200px', sm: '300px', md: '400px', lg: '500px' }
            }} />
            <HRGold />
            <Box sx={{ height: '10px', color: ColorBackground, backgroundColor: ColorAccent, }}>
            </Box>
            <HRGold />
            <Container>
                <Box pt={5} sx={{ fontSize: '4rem', display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                    Your Future Starts Here.
                </Box>
                <Box pl={0.3} sx={{ fontSize: '1.8rem', display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                    Scholarships are now available for 2024.
                </Box>
                <Box mt={3}>
                    <Button variant='outlined' href="/admissions/apply-now" sx={{ width: '100%', fontSize: '24pt', fontFamily: 'inherit' }}>Apply Now</Button>
                </Box>
                <Box pt={3} pb={10} sx={{ fontSize: '2rem', }}>
                    This is a place where the Word of God and the Holy Spirit come together to produce life, birth ministries, and launch individuals to fulfill the call of God.
                </Box>
            </Container>
        </>
    );
}

export default HomePage;
