import React from 'react';
import styled from '@emotion/styled'
import { Outlet } from "react-router-dom";
import { Box, Container } from '@mui/material';
import FubiLogo from './components/FubiLogo';
import TemporaryDrawer from './components/TemporaryDrawer';

const ColorBackground = "#f7f7f7";
const ColorAccent = '#003683';
const HR = styled.div`
  background-color: ${ColorAccent};
  height: 10px;
`;
const HRGold = styled.div`
  background-color: #ffbd17;
  height: 1px;
`;
const Body = styled.div`
  background-color: ${ColorBackground};
  margin: 10px 0 10px 0;
  font-family: Garamond, serif;
  font-size: 2rem;
  display: flex;
`;

function Frame() {
  return (
    <>
      <HR />
      <HRGold />
      <Container>
        <Body>
          <FubiLogo />
          <Box sx={{ fontSize: '1.3rem', display: 'flex', alignItems: 'center', justifyContent: 'right', width: '100%', color: ColorAccent, }}>
            <TemporaryDrawer />
          </Box>
        </Body>
      </Container>
      <HRGold />
      <HR />
      <HRGold />
      <Outlet />
      <HRGold />
      <Box sx={{ color: ColorBackground, backgroundColor: ColorAccent, }}>
        <Container>
          <Box p={2} sx={{ lineHeight: '1.1rem', fontSize: '0.8rem', display: 'block', textAlign: 'center', textTransform: 'uppercase' }}>
            Copyright &copy; 2023 Faith United Bible Institute. All Rights Reserved.<br />
            Website by <a style={{ color: 'white', cursor: 'pointer', textDecoration: 'underline' }} href="https://www.sailwindtech.com" id="saillink">Sailwind Technologies</a>
          </Box>
        </Container>
      </Box>
      <HRGold />
    </>
  );
}

export default Frame;
