import React from 'react';
import { Box, Container } from '@mui/material';
import useTitle from '../../components/useTitle';

function DressCodePage() {
    useTitle('Dress Code');
    return (
        <Container>
            <Box>
                <h1>Dress Code</h1>
            </Box>
            <Box pb={10}>
                <p>
                    The purpose of Faith United Bible Institute is to not only strengthen the student's Christian walk, but also to prepare the student for ministry. With this in mind, professionalism in dress and conduct is imperative. It is the
                    desire of our school that all of our students be dressed modestly and in good taste. The dress code outlined
                    below applies at all times when attending classes, during school hours, and during church services.
                </p>
                <p>
                    Note: If you are in violation of the dress code, may be asked to change into acceptable attire or to leave
                    the campus. You will be counted absent for any classes missed that day.
                    The Dean, any faculty member, school staff member, or Dress Code Monitor is authorized to speak to you if
                    you are out of dress code. If there is a question about any aspect of dress code, the Dean's word is final. We
                    reserve the right to amend the dress code during the year if necessary.
                </p>
                <p>
                    Note: When serving in the Faith United Teams program for your ministry of helps (MOH) assignment, please
                    check with your department supervisor regarding dress code. You are allowed to dress according to your MOH
                    assignment for only the services you serve in. For all other services you must be in school dress code. No
                    exceptions.
                </p>
                <h3>BUSINESS CASUAL DRESS CODE</h3>
                <h4>WOMEN - Acceptable Attire</h4>
                <ul>
                    <li>Dresses, blouses, skirts, collared shirts, suits, blazers/jackets, sweaters, and turtlenecks with dress slacks (including dress type khakis, corduroys, capris or cropped pants, or other non-denim slacks).</li>
                    <li>Dress/skirt length must be mid-knee or longer. Hemline of fitted skirts should be mid-knee or longer. Any slit on the front of a skirt or dress may not rise above the knee. Any slit on the side or back may not exceed 3-4 inches above the knee.</li>
                    <li>Leggings may only be worn with an appropriate length skirt (mid-knee length or longer).</li>
                    <li>Slacks must cover the hip, preferably worn at the waist (no hip-huggers).</li>
                    <li>No tight-fitting slacks or skirts are to be worn.</li>
                    <li>At no time should underwear be visible when you bend over.</li>
                    <li>Undergarments should not be visible through your clothing. Any sheer garments should be accompanied by a non-see through garment underneath (e.g. shells).</li>
                    <li>Undergarments should be supportive and not show any details of the anatomy.</li>
                    <li>Acceptable shoes include dressy sandals, dress shoes, dressy-casual shoes and boots.</li>
                    <li>Note: If wearing sandals, please have well-groomed feet and toenails.</li>
                    <li>Note: Shirts, blouses, and sweaters must cover stomach and midriff area entirely at all times, even when arms are raised.</li>
                    <li>Hair must be clean, neatly groomed and well kept.</li>
                    <li>All students are expected to observe good hygiene and wear clean clothing.</li>
                </ul>
                <h4>WOMEN - Unacceptable Attire</h4>
                <ul>
                    <li>Tops with spaghetti straps, sleeveless dresses or blouses, any clothing with a low-cut neckline, revealing or clingy blouses or sweaters, T-shirts, or sweatpants. NO cleavage should be showing at any time. If you draw an imaginary line from armpit to armpit, your blouse should not dip below that line.</li>
                    <li>Miniskirts, skorts, shorts, gauchos, or jeans of any kind or color, painter pants, cargo pants or pants with pockets down the leg, or hip-huggers.</li>
                    <li>“Fishnet” stockings</li>
                    <li>Flip-flops, tennis shoes of any kind, athletic shoes, hiking boots, sport socks, etc.</li>
                    <li>Gothic attire or extreme accessories.</li>
                </ul>
                <h4>MEN - Acceptable Attire</h4>
                <ul>
                    <li>Dress or casual slacks, khakis, corduroys, or Dockers-type slacks. Slacks must be worn at the waist. No
                        baggy or saggy pants allowed. Please note not all Dockers brands are slacks and all Khakis are not
                        either.</li>
                    <li>Collared shirts, oxfords, Polo's, or short sleeve shirts with collars may be worn. Ties are optional. Shirts
                        must be tucked in unless they have squared bottoms which are designed to be worn outside. Shirts may
                        not have advertisements, excessive designs, wording, or logos on them.</li>
                    <li>Dress shoes or dressy casual shoes with socks are requires (no sport socks allowed).</li>
                    <li>Beards and mustaches must be kept clean, groomed, and at an appropriate length.</li>
                    <li>Hair must be neatly groomed and well-kept. Longer hair must be tied up and out of the face.</li>
                    <li>All students are expected to observe good hygiene and wear clean clothing.</li>
                </ul>
                <h4>MEN - Unacceptable Attire</h4>
                <ul>
                    <li>Painter pants, cargo pants or pants with pockets down the leg, shorts, or sweatpants.</li>
                    <li>T-shirts of any style, or sweatshirts.</li>
                    <li>Flip-flops, sandals, slides, tennis shoes, hiking boots, any type of athletic shoes, canvas shoes with rubber bottom, etc.</li>
                    <li>Gothic attire or extreme accessories.</li>
                </ul>
                <h3>CASUAL DRESS CODE - Class Time</h3>
                <strong>Women</strong>
                <ul>
                    <li>Jeans- bottom covered (same as business casual wear)</li>
                    <li>Shirts/ t-shirts</li>
                    <li>No tank tops/ shirts with holes/ no spaghetti straps</li>
                    <li>No leggings, Spandex</li>
                    <li>Fitted pants permitted with shirt covering bottom completely</li>
                </ul>
                <strong>Men</strong>
                <ul>
                    <li>Jeans, no holes, not tight fitting / skinny jeans</li>
                    <li>Nice casual / tennis shoes</li>
                    <li>Shirts / t-shirts</li>
                    <li>Absolutely no tank tops/ shirts ripped or holes</li>
                    <li>No joggers</li>
                </ul>
                <h3>CASUAL DRESS CODE - Soulwinning / S.O.S</h3>
                <ul>
                    <li>Shorts are allowed, but absolutely no short shorts, mid leg or longer for both men and women.</li>
                    <li>Casual dress code for class time
                        All clothing is to be pressed and neat without stains, holes, tears, or ragged edges on clothing.
                        Students found in violation of the dress code, as stated above, will be sent home to change. Below are the
                        procedures for dress code violations in addition to being sent home to change clothes and being marked absent
                        for the entire day.</li>
                    <li>1st Violation - Verbal warning.</li>
                    <li>2nd Violation - Written warning signed by the student.</li>
                    <li>3rd Violation - Written warning signed by the student and meeting with the Dean.</li>
                    <li>4th Violation - 2 day Suspension.</li>
                </ul>
            </Box >
        </Container >
    );
}

export default DressCodePage;
