import * as React from 'react';
import styled from '@emotion/styled'
import { Box, Drawer, Button, Container, Grid, } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import FubiLogo from './FubiLogo'

const ColorBackground = "#f7f7f7";
const ColorAccent = '#003683';
const Link = styled.a`
  display: block;
  margin: 15px;
`;
const LinkTitle = styled.div`
  display: block;
  margin: 20px 15px 20px 15px;
  font-size: 1.9rem;
`;
const HR = styled.div`
  background-color: ${ColorAccent};
  height: 10px;
`;
const HRGold = styled.div`
  background-color: #ffbd17;
  height: 1px;
`;

const TemporaryDrawer = () => {
  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <>
      <Box sx={{
        fontSize: '1.1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Box sx={{
          display: { xs: 'none', md: 'flex', },
          fontSize: '1.3rem',
        }}>
          <Box m={1}><a href="/about/giving">GIVING</a></Box>
          <Box m={1}><a href="/admissions/apply-now">APPLY NOW</a></Box>
        </Box>
        <Button onClick={toggleDrawer('top', true)}>
          <MenuIcon sx={{ width: '30px', height: '40px', color: ColorAccent }} />
        </Button>
      </Box>
      <Drawer
        anchor={'top'}
        open={state['top']}
        onClose={toggleDrawer('top', false)}
        sx={{ fontFamily: 'inherit', backgroundColor: ColorBackground }}
      >
        <Box
          sx={{ width: 'auto', }}
          role="presentation"
          onClick={toggleDrawer('top', false)}
          onKeyDown={toggleDrawer('top', false)}
        >
          <Box sx={{
            fontSize: '1.4rem',
          }}>
            <HR />
            <HRGold />
            <Container>
              <Box my={1} sx={{ display: 'flex' }}>
                <FubiLogo />
                <Box sx={{ fontSize: '1.1rem', display: 'flex', width: '100%', justifyContent: 'right', alignItems: 'center', }}>
                  <Button>
                    <CloseIcon sx={{ width: '30px', height: '40px', color: ColorAccent }} />
                  </Button>
                </Box>
              </Box>
            </Container>
            <HRGold />
            <HR />
            <HRGold />
            <Container>
              <Box mt={2}>
                <Grid container>
                  <Grid item xs={12} md={6} p={1}>
                    <LinkTitle>ABOUT</LinkTitle>
                    <Box>
                      <Link href="/about/giving">GIVING</Link>
                      <Link href="/about/about-fubi">ABOUT FUBI</Link>
                      <Link href="/about/vision-and-purpose">VISION & PURPOSE</Link>
                      <Link href="/about/statement-of-faith">STATEMENT OF FAITH</Link>
                      <Link href="/about/contact-us">CONTACT US</Link>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} p={1}>
                    <LinkTitle>ADMISSIONS</LinkTitle>
                    <Box>
                      <Link href="/admissions/apply-now">APPLY NOW</Link>
                      <Link href="/admissions/faculty">FACULTY</Link>
                      <Link href="/admissions/financial-information">FINANCIAL INFORMATION</Link>
                      <Link href="/admissions/application-procedure">APPLICATION PROCEDURE</Link>
                      <Link href="/admissions/faqs">FAQ's</Link>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} p={1}>
                    <LinkTitle>ACADEMICS</LinkTitle>
                    <Box>
                      <Link href="/academics/course-information">COURSE INFORMATION</Link>
                      <Link href="/academics/transcript-request">TRANSCRIPT REQUEST</Link>
                      <Link href="/academics/accreditation">ACCREDITATION</Link>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} p={1}>
                    <LinkTitle>STUDENTS</LinkTitle>
                    <Box>
                      <Link href="/students/facilities">FACILITIES</Link>
                      <Link href="/students/student-life">STUDENT LIFE</Link>
                      <Link href="/students/dress-code">DRESS CODE</Link>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
            <HRGold />
            <HR />
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default TemporaryDrawer;