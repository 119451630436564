import React from 'react';
import { Box, Button, Container, Grid } from '@mui/material';
import useTitle from '../../components/useTitle';

function ApplyNowPage() {
    useTitle('Apply Now');
    return (
        <Container>
            <Grid container pt={5}>
                <Grid item xs={12} sm={6} md={5} lg={4} p={1}>
                    <Box>
                        <Box sx={{ fontSize: '4.2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            Apply Now
                        </Box>
                        <Box mt={1} mb={3} sx={{ fontSize: '1.2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            * All application fees are non-refundable.
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                        <Box sx={{ maxWidth: '300px', }}>
                            <img src="/img/fubi-logo-new.png" alt="Logo" width="100%" />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={7} lg={8} p={1}>
                    <Box pb={3} sx={{ fontSize: '2.5rem', }}>
                        Application for Admission
                    </Box>
                    <Box>
                        <Box>
                            <Button sx={{ width: '100%', fontSize: '1.2rem' }} variant="outlined" href="https://myfaithunitedchurch.churchcenter.com/people/forms/565504">New Application</Button>
                        </Box>
                        <Box sx={{ display: 'flex', fontSize: '1.3rem' }} pt={2} pb={1}>
                            <Box pr={1.5}>
                                <img alt="notepad" src="/img/notepad.png" width="60px" height="70px" />
                            </Box>
                            If you are a citizen or legal resident of the United States of America and are interested in attending Faith United Bible Institute, start your application here.
                        </Box>
                    </Box>
                    <Box py={3} sx={{ fontSize: '2.5rem', }}>
                        Continuing Students <strong>ONLY</strong>
                    </Box>
                    <Box pb={3}>
                        <Box>
                            <Button sx={{ width: '100%', fontSize: '1.2rem' }} variant="outlined" href="https://myfaithunitedchurch.churchcenter.com/people/forms/565538">Continuing Student</Button>
                        </Box>
                        <Box sx={{ display: 'flex', fontSize: '1.3rem' }} pt={2}>
                            <Box pr={1.5} pb={1}>
                                <img alt="notepad" src="/img/notepad.png" width="60px" height="70px" />
                            </Box>
                            If you are a returning student and are looking to continue your education with Faith United Bible Institute, choose this application.
                        </Box>
                    </Box>
                    <Box pb={10}>
                        <Box>
                            <Button sx={{ width: '100%', fontSize: '1.2rem' }} variant="outlined" href="https://myfaithunitedchurch.churchcenter.com/people/forms/565551">Internship</Button>
                        </Box>
                        <Box sx={{ display: 'flex', fontSize: '1.3rem' }} pt={2}>
                            <Box pr={1.5} pb={1}>
                                <img alt="notepad" src="/img/notepad.png" width="60px" height="70px" />
                            </Box>
                            If you will be a fourth-year student and want to apply for the internship, which is a requirement for our bachelors degree program, choose this application.
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default ApplyNowPage;
