import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import useTitle from '../../components/useTitle';

function AccreditationPage() {
    useTitle('Accreditation');
    return (
        <Container>

            <Box pb={10} sx={{ fontSize: '1.5rem', }}>
                <Grid container>
                    <Grid item xs={12} md={7}>
                        <Box>
                            <h1>Accreditation</h1>
                        </Box>
                        <Box>
                            <p>Faith United Bible Institute is an accredited institute of higher learning, affiliated with the <strong>Transworld Accrediting Commission International</strong>.</p>
                            <p>Transworld Accrediting Commission is an international, non-governmental, educational accrediting body, federally recognized as a non-profit church organization. Transworld has established high standards of excellence and shared Biblical Doctrine. Transworld accredits and assists Theological Schools, Seminaries, Universities, Colleges, and Programs throughout the World.</p>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5} px={3}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', }}>
                            <Box>
                                <img alt="Transworld Accreditation" src="/img/taci-logo.png" />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={12} pt={4} sx={{ textAlign: 'center' }}>
                        <Box p={1}>
                            TRANSWORLD ACCREDITING COMMISSION INTERNATIONAL<br />
                            9085 California Ave<br />
                            Riverside, CA 92503<br />
                        </Box>
                        <Box p={1}>
                            office@transworldaccrediting.com<br />
                            Tel: (951) 901-5586<br />
                            Fax: (951) 344-8236<br />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container >
    );
}

export default AccreditationPage;
