import React from 'react';
import { Box, Button, Container, } from '@mui/material';
import useTitle from '../../components/useTitle';

function GivingPage() {
    useTitle('Giving');
    return (
        <Container>
            <Box>
                <h1>Giving</h1>
                <p>
                    Faith United Bible Institute exists to raise a generation that will lift Jesus high in the nations and carry Him to the world.
                    We believe that a quality spiritual education that trains an individual for service to the Lord Jesus Christ should not leave that individual with oppressive financial debt.
                    Many of our students receive a scholarship to cover the cost of tuition.
                    You can support the vision and continued efforts of Faith United Bible Institute with your generous gift.<br /><br />
                    <strong>If you are interested in sponsoring a student, call our office at 386-837-1815.</strong>
                </p>
            </Box>
            <Box pb={10}>
                <Button sx={{ fontSize: '1.2rem', fontFamily: 'inherit', minWidth: '300px', marginRight: '10px', marginBottom: '10px' }} variant="outlined" href="https://app.easytithe.com/App/Form/ead9418f-b6d7-4b57-b2b8-5788139ebef1">Donate Now</Button>
            </Box>
        </Container>
    );
}

export default GivingPage;
